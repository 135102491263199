<template>
	<div>
		<Header :title="isEdit ? '修改商城信息' : '新增商城信息'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 5, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }">
			
			<a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
				<a-select :disabled="isEdit" placeholder="请选择" v-model:value="modelRef.organizationId" @change="getAllCinemaList">
					<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
				</a-select>
			</a-form-item>
			
			<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
				<a-select :disabled="isEdit" placeholder="请选择" v-model:value="modelRef.cinemaId">
					<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
				</a-select>
			</a-form-item>
			
			<a-form-item label="影院城市" name="addressIds" :rules="[{required: true, message: '必选项不允许为空'}]">
				<addressSelect :disabled="isSee" v-model:value="modelRef.addressIds" @change="onAddress"></addressSelect>
			</a-form-item>
			
			<a-form-item label="商城名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.title" placeholder="请输入影院名称"></a-input>
			</a-form-item>
			
			<a-form-item label="商城地址" name="address" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-textarea :disabled="isSee" v-model:value="modelRef.address" placeholder="请输入商城地址"></a-textarea>
			</a-form-item>
			
			<a-form-item label="服务电话" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.phone" placeholder="请输入服务电话"></a-input>
			</a-form-item>
			
			<a-form-item label="服务时间" name="serviceTime" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-textarea :disabled="isSee" v-model:value="modelRef.serviceTime" placeholder="请输入服务时间"></a-textarea>
			</a-form-item>
			
			<a-form-item label="地址经纬度" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]">
				<div class="ui-custom__btnInput">
					<a-input :disabled="isSee" v-model:value="modelRef.coordinate" placeholder="请填写地址经纬度"></a-input>
					<a-button :disabled="isSee" class="ui-custom__btn" type="primary" @click="onOpenWin">坐标拾取器</a-button>
				</div>
			</a-form-item>
			
			<a-form-item label="交通信息" name="trafficinfo">
				<a-textarea :disabled="isSee" v-model:value="modelRef.trafficinfo" placeholder="请输入交通信息"></a-textarea>
			</a-form-item>
			
			<a-form-item label="自提地址" name="takeAddress">
				<a-textarea :disabled="isSee" v-model:value="modelRef.takeAddress" placeholder="请输入自提地址"></a-textarea>
			</a-form-item>
			
			<a-form-item label="提货步骤" name="takeProcess">
				<a-textarea :disabled="isSee" v-model:value="modelRef.takeProcess" placeholder="请输入提货步骤"></a-textarea>
			</a-form-item>
			<a-row>
				<a-col :offset="1" :span="20">
					<a-row>
						<a-col :span="7">
							<a-form-item label="是否可用" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 16, xxl: 17}" :wrapperCol="{span: 8, xxl: 7 }">
								<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="是" un-checked-children="否"></a-switch>
							</a-form-item>
						</a-col>
						<!-- <a-col :span="7">
							<a-form-item label="主副卡商品是否可退款" name="isMastercardRefund" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 16, xxl: 17}" :wrapperCol="{span: 8, xxl: 7 }">
								<a-switch v-model:checked="modelRef.isMastercardRefund" checked-children="是" un-checked-children="否"></a-switch>
							</a-form-item>
						</a-col> -->
						<!-- <a-col :span="7">
							<a-form-item label="优惠券商品是否可退款" name="isCouponRefund" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 16, xxl: 17}" :wrapperCol="{span: 8, xxl: 7 }">
								<a-switch v-model:checked="modelRef.isCouponRefund" checked-children="是" un-checked-children="否"></a-switch>
							</a-form-item>
						</a-col> -->
					</a-row>
				</a-col>
			</a-row>
			<!-- <a-row>
				<a-col :offset="1" :span="20">
					<a-row>
						<a-col :span="7">
							<a-form-item label="普通商品是否可退款" name="isNormalRefund" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 16, xxl: 17}" :wrapperCol="{span: 8, xxl: 7 }">
								<a-switch v-model:value="modelRef.isNormalRefund" checked-children="是" un-checked-children="否"></a-switch>
							</a-form-item>
						</a-col>
						<a-col :span="7">
							<a-form-item label="次卡商品是否可退款" name="isTimescardRefund" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 16, xxl: 17}" :wrapperCol="{span: 8, xxl: 7 }">
								<a-switch v-model:value="modelRef.isTimescardRefund" checked-children="是" un-checked-children="否"></a-switch>
							</a-form-item>
						</a-col>
						<a-col :span="7">
							<a-form-item label="年卡商品是否可退款" name="isYearcardRefund" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 16, xxl: 17}" :wrapperCol="{span: 8, xxl: 7 }">
								<a-switch v-model:value="modelRef.isYearcardRefund" checked-children="是" un-checked-children="否"></a-switch>
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row> -->
			
			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button v-if="!isSee" type="primary" @click="onSubmit(true)">提交</a-button>
					<!-- <a-button type="primary" style="margin-left: 20px;" @click="onSubmit(false)">添加并继续</a-button> -->
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import addressSelect from '@/components/addressSelect/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getStoreDetail, saveStore } from '@/service/modules/mall.js';
	export default {
		components: {Header, addressSelect},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
			this.getOrganizationList();
			if(this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				modelRef: {
					isDisabled: false,
					addressIds: []
				}
			}
		},
		methods: {
			onBack(isRef) {
				if (isRef) {
					this.$emit('back', isRef);
				} else {
					this.$emit('back');
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getStoreDetail({
						cinemaId: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.addressIds = [Number(ret.data.provinceId), Number(ret.data.cityId)];
						if (ret.data.areaId) {
							ret.data.addressIds.push(Number(ret.data.areaId));
						}
						if (ret.data.organizationId) {
							this.getAllCinemaList(ret.data.organizationId)
						}
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
						ret.data.isMastercardRefund = ret.data.isMastercardRefund ? true : false;
						ret.data.isCouponRefund = ret.data.isCouponRefund ? true : false;
						ret.data.isNormalRefund = ret.data.isNormalRefund ? true : false;
						ret.data.isTimescardRefund = ret.data.isTimescardRefund ? true : false;
						ret.data.isYearcardRefund = ret.data.isYearcardRefund ? true : false;
						ret.data.coordinate = ret.data.lat + ',' + ret.data.lon;
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit(isBack) {
				this.$refs.form.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.isDisabled = postData.isDisabled ? 0 : 1;
					postData.isMastercardRefund = postData.isMastercardRefund ? 1 : 0;
					postData.isCouponRefund = postData.isCouponRefund ? 1 : 0;
					postData.isNormalRefund = postData.isNormalRefund ? 1 : 0;
					postData.isTimescardRefund = postData.isTimescardRefund ? 1 : 0;
					postData.isYearcardRefund = postData.isYearcardRefund ? 1 : 0;
					
					postData.lat= postData.coordinate.split(',')[0];
					postData.lon = postData.coordinate.split(',')[1];
					postData.provinceId = postData.addressIds[0];
					postData.cityId = postData.addressIds[1];
					if (postData.addressIds.length === 3) {
						postData.areaId = postData.addressIds[2];
					}
					delete postData.coordinate;
					delete postData.addressIds;
					this.loading = true;
					try {
						let ret = await saveStore(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							if (isBack) {
								this.onBack(true);
							} else {
								this.$refs.form.resetFields();
								this.onAddress(false);
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				this.organizationList = ret.data.list || [];
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
			},
			onAddress(data) {
				if (data) {
					this.modelRef.province = data[0].fullname;
					this.modelRef.city = data[1].fullname;
					if (data.length === 3) {
						this.modelRef.area = data[2].fullname;
					}
				} else {
					this.modelRef.province = '';
					this.modelRef.city = '';
					this.modelRef.area = '';
				}
			}
		}
	}
</script>

<style scoped>
	.ui-custom__btnInput {
		position: relative;
	}
	.ui-custom__btn {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	}
</style>
