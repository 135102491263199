<template>
    <div>
        <a-cascader :disabled="disabled" v-model:value="city" :fieldNames="fieldNames" :options="options" @change="onChange" placeholder="请选择" />
    </div>
</template>

<script>
    import { getDivision } from "@/service/modules/system";
    export default {
        props: {
            value: {
                type: Array,
                default: () => []
            },
			disabled: {
				type: Boolean,
				default: false
			}
        },
        data() {
            return {
                fieldNames: {
                    label: 'fullname',
                    value: 'id',
                    children: 'sub'
                },
                city: [],
                options: []
            }
        },
        created() {
            this.getDivision();
        },
        methods: {
            getDivision() {
                getDivision({}).then(res => {
                    this.options = res.data.filter(function(item) {
                            item.sub = res.data.filter(function (childItem) {
                            return (item.id == childItem.parentId)
                        })
                        return item.level == 0;
                    });
					this.city = JSON.parse(JSON.stringify(this.value));
                })
            },
            onChange(value, selectedOptions) {
                this.$emit('update:value', value);
                this.$emit('change', selectedOptions);
            }
        },
    }
</script>